import type { OnProgress } from ".";
import { Organization } from "../resources";
import type { OrganizationEntity } from "../types";

export async function getAllOrganizations(
  ids: string[],
  onProgress?: OnProgress,
) {
  const organizations: OrganizationEntity[] = [];
  const limit = 100;
  const dedupedIds = ids.dedupe();
  let offset = 0;

  while (true) {
    const orgs = await Organization.search({
      ids: dedupedIds.slice(offset, offset + limit),
      limit: limit,
    });

    organizations.push(...orgs.data);
    onProgress?.(Math.min(1, (offset + limit) / dedupedIds.length));

    if (offset + limit >= dedupedIds.length) {
      break;
    }

    offset += limit;
  }

  return organizations;
}
